import host from "../config/api";
import axios from "axios";
const host_ =
  "https://restcountries.eu/rest/v2/all?fields=name;callingCodes;flag;translations";

class CountriesService {
  async getCountries() {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios.get(host_, config).then((res) => {
      return res;
    });
  }

  async getIpAdress() {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let ip = "";
    ip = await axios
      .get("https://api.ipify.org/?format=json", config)
      .then((res) => {
        ip = res.data.ip;
        return res.data.ip;
      });
    return axios
      .post(
        host + "countries?token=2893A08E103F56112F9887477430A9EC16B9999",
        { ip: ip },
        config
      )
      .then((res) => {
        console.log(res, "res");
        return res.data.code;
      });
  }

  // async getIpAdressCountryCode(ip) {
  //   let config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   return
  // }
}
export default CountriesService;
