import host from "../config/api";
import axios from "axios";
const host_ = host + "api/upload-file/";

class UploadService {
    async upload(body) {
        return axios.post(host_, body).then(res => {
            return res;
        });
    }
    
}
export default UploadService