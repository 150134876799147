import host from "../config/api";
import axios from "axios";
const host_ = host + "api/project/";
class ProjectService {
  async getProjectInfos(ref) {
    let config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    let data = { reference: ref };
    return axios.post(host_ + "get-project-info", data, config).then(res => {
      return res;
    });
  }

  async getProjects(ref) {
    let config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    let data = { reference: ref };
    return axios.post(host_ + "get-projects", data, config).then(res => {

      return res;
    });
  }
  async getAllProjects() {
    return new Promise((resolve, reject) => {
      let config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      return axios.get(host_ + "get-all-projects", config).then(res => {
        // Filter projects array to find the project with reference "CH28473"
        const projectToInsert0 = res.data.projects.find(project => project.reference === "CH28473");
        
        if (projectToInsert0) {
          // Remove the project from its original position
          const filteredProjects = res.data.projects.filter(project => project.reference !== "CH28473");
          // Insert the project at index 0
          filteredProjects.splice(0, 0, projectToInsert0);
          // Update the projects array in the response
          res.data.projects = filteredProjects;
        } else {
          reject(new Error("Project with reference CH28473 not found"));
        }
        // Filter projects array to find the project with reference "CH28471"
        const projectToInsert1 = res.data.projects.find(project => project.reference === "CH28471");
        
        if (projectToInsert1) {
          // Remove the project from its original position
          const filteredProjects = res.data.projects.filter(project => project.reference !== "CH28471");
          // Insert the project at index 1
          filteredProjects.splice(1, 0, projectToInsert1);
          // Update the projects array in the response
          res.data.projects = filteredProjects;
        } else {
          reject(new Error("Project with reference CH28471 not found"));
        }
        // Filter projects array to find the project with reference "CH28465"
        const projectToInsert2 = res.data.projects.find(project => project.reference === "CH28465");
        
        if (projectToInsert2) {
          // Remove the project from its original position
          const filteredProjects = res.data.projects.filter(project => project.reference !== "CH28465");
          // Insert the project at index 2
          filteredProjects.splice(2, 0, projectToInsert2);
          // Update the projects array in the response
          res.data.projects = filteredProjects;
        } else {
          reject(new Error("Project with reference CH28465 not found"));
        }
        // Filter projects array to find the project with reference "CH28472"
        const projectToInsert3 = res.data.projects.find(project => project.reference === "CH28472");
        
        if (projectToInsert3) {
          // Remove the project from its original position
          const filteredProjects = res.data.projects.filter(project => project.reference !== "CH28472");
          // Insert the project at index 3
          filteredProjects.splice(3, 0, projectToInsert3);
          // Update the projects array in the response
          res.data.projects = filteredProjects;
        } else {
          reject(new Error("Project with reference CH28472 not found"));
        }
        // Filter projects array to find the project with reference "CH28468"
        const projectToInsert4 = res.data.projects.find(project => project.reference === "CH28468");
        
        if (projectToInsert4) {
          // Remove the project from its original position
          const filteredProjects = res.data.projects.filter(project => project.reference !== "CH28468");
          // Insert the project at index 1
          filteredProjects.splice(4, 0, projectToInsert4);
          // Update the projects array in the response
          res.data.projects = filteredProjects;
        } else {
          reject(new Error("Project with reference CH28468 not found"));
        }
        // Filter projects array to find the project with reference "CH28469"
        const projectToInsert5 = res.data.projects.find(project => project.reference === "CH28469");
        
        if (projectToInsert5) {
          // Remove the project from its original position
          const filteredProjects = res.data.projects.filter(project => project.reference !== "CH28469");
          // Insert the project at index 5
          filteredProjects.splice(5, 0, projectToInsert5);
          // Update the projects array in the response
          res.data.projects = filteredProjects;
        } else {
          reject(new Error("Project with reference CH28469 not found"));
        }
        // Filter projects array to find the project with reference "CH28467"
        const projectToInsert6 = res.data.projects.find(project => project.reference === "CH28467");
        
        if (projectToInsert6) {
          // Remove the project from its original position
          const filteredProjects = res.data.projects.filter(project => project.reference !== "CH28467");
          // Insert the project at index 6
          filteredProjects.splice(6, 0, projectToInsert6);
          // Update the projects array in the response
          res.data.projects = filteredProjects;
        } else {
          reject(new Error("Project with reference CH28467 not found"));
        }
        // Filter projects array to find the project with reference "CH28470"
        const projectToInsert7 = res.data.projects.find(project => project.reference === "CH28470");
        
        if (projectToInsert7) {
          // Remove the project from its original position
          const filteredProjects = res.data.projects.filter(project => project.reference !== "CH28470");
          // Insert the project at index 7
          filteredProjects.splice(7, 0, projectToInsert7);
          // Update the projects array in the response
          res.data.projects = filteredProjects;
        } else {
          reject(new Error("Project with reference CH28470 not found"));
        }
        
        resolve(res);
      }).catch(error => {
        reject(error);
      });
    });
  }  

  async getProjectByRoute(route) {
    let config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    let data = { route: route };
    return axios.post(host_ + "get-project-by-route", data, config).then(res => {

      return res;
    });
  }
}
export default ProjectService;
