import host from "../config/api";
import axios from "axios";
const host_ = host + "api/email/";

class EmailService {
    async sendEmail(body) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return axios.post(host_, body, config).then(res => {
            return res;
        });
    }
    async getUtms(params) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return axios.post(host_ + 'get-utms' + params, {}, config).then(res => {
            return res;
        });

    }
    async sendCarriere(params) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return axios.post(host_ + 'send-carriere', params, config).then(res => {
            return res;
        });
    }
}
export default EmailService