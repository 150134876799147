import host from "../config/api";
import axios from "axios";
const host_ = host + "api/actualite/";

class ActualityService {
  async getActualityInfos(ref) {
    let config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    let data = { ref };
    return axios.post(host_ + "get-actualite-info", data, config).then(res => {
      return res;
    });
  }

  async getActualities() {
    return new Promise((resolve, reject) => {
      let config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      return axios.get(host_ + "get-all-actualites", config).then(res => {
        resolve(res);
      });
    });
  }
}
export default ActualityService;
